import Simg1 from '../../assets/img/service-bg-01.jpg';
import Simg2 from '../../assets/img/service-bg-02.jpg';
import AbsoluteText from './absoluteText';
import ServiceButton from './serviceButton';
export default function HomeServiceSecond() {
    return (
        <>
            <div className="w-full flex max-lg:flex-col bg-bg-dark12 relative cursor-pointer">
                <div className=" lg:w-1/2 relative after:absolute after:w-full after:h-full after:top-0 overflow-hidden after:left-0 after:bg-[rgba(0,0,0,.5)] group">
                    <img src={Simg1} className="transition-all duration-300 group-hover:scale-125" />
                </div>
                <div className=' lg:w-1/2 px-14 flex flex-col justify-center'>
                    <div className='xl:w-[80%]  py-12  relative flex flex-col space-y-6'>
                        <h1 className='Absolute__Text_Second text-[40px]  sm:text-[30px] lg:text-[50px] xl:text-[66px] transition-all duration-300 hover:-top-0'>SERVICE</h1>
                        <div className='relative z-20 text-main-text font-bold font-chakra text-[30px] xl:text-[42px] leading-10'>
                            CAR EXTERIOR CLEANING: <br /> BASIC DETAILING
                        </div>
                        <div>
                            <ul className='flex flex-wrap'>
                                <li className="w-full sm:w-1/2 relative pl-6 after:absolute after:left-0 after:top-1.5 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main">SAME DAY SERVICE</li>
                                <li className="w-full sm:w-1/2 relative pl-6 after:absolute after:left-0 after:top-1.5 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main">ONLINE APPOINTMENT</li>
                                <li className="w-full sm:w-1/2 relative pl-6 after:absolute after:left-0 after:top-1.5 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main">CONVENIENT LOCATION</li>
                                <li className="w-full sm:w-1/2 relative pl-6 after:absolute after:left-0 after:top-1.5 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main">COMPLIMENTARY SHUTTLE</li>
                            </ul>
                        </div>
                        <div>
                            <ServiceButton title={' GET SERVICE '} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex max-lg:flex-col bg-bg-dark12 relative cursor-pointer">

                <div className=' lg:w-1/2 px-6 flex flex-col justify-center'>
                    <div className=' py-12  relative flex flex-col space-y-6'>
                        <h1 className='Absolute__Text_Second text-[40px]  sm:text-[30px] lg:text-[50px] xl:text-[66px] transition-all duration-300 hover:-top-0'>SERVICE</h1>
                        <div className='relative z-20 text-main-text font-bold font-chakra text-[30px] xl:text-[42px] leading-10'>
                            RUBBING, WAXING, AND POLISHING
                        </div>
                        <div className='text-[16px] xl:pr-24 text-main-text font-chakra'>
                            Our technicians have undergone the most extensive and stringent car detail training program. And the only car that matters is yours because we will detail it to your complete satisfaction.
                        </div>
                        <div>
                            <ServiceButton title={' GET SERVICE '} />
                        </div>
                    </div>
                </div>
                <div className=" lg:w-1/2 relative after:absolute after:w-full after:h-full after:top-0 overflow-hidden after:left-0 after:bg-[rgba(0,0,0,.5)] group">
                    <img src={Simg2} className="transition-all duration-300 group-hover:scale-125" />
                </div>
            </div>
        </>
    )
}