import { Routes, Route } from "react-router-dom";
import Header from "./Pages/layouts/header";
import "./assets/js/script";
import AnimatedCursor from "./Pages/Components/animatedCursor";
import Home from "./Pages/home";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Pages/layouts/footer";
function App() {
  return (
    <>
    <AnimatedCursor />
      <Header />
      <Routes>
          <Route path={'/'} element={<Home />} />
      </Routes>
      <Footer />  
    </>
  );
}

export default App;
