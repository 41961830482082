import Logo from "../../assets/img/logo.png"
import ServiceButton from "../Components/serviceButton"
export default function Footer() {
    return (
        <>
            <div className="w-full bg-bg-dark12 py-20 mt-20">
                <div className="max-w-6xl mx-auto max-xl:px-6 flex flex-wrap">
                    <div className="sm:w-1/2 lg:w-1/3 xl:w-1/4 ">
                        <img src={Logo} className="w-[186px] h-[35px]" />
                        <p className="text-main-text text-[16px] font-mulish mt-6">Every service is rigorously screened and constantly rated to ensure you get the best service.</p>
                        <p className="text-main-text text-[13px] font-mulish mt-6">Support center 24/7</p>
                        <p>
                            <a href="to:+11234567890" className="font-bold font-chakra text-main-text text-[23px] transition-all duration-300 hover:text-main">+ 1 123 456-7890</a>
                        </p>
                    </div>
                    <div className="w-full max-lg:mt-12 lg:w-2/3 xl:w-2/4 flex max-sm:flex-col">
                        <div className="sm:w-1/3 flex flex-col lg:items-center">
                            <p className="font-bold font-chakra text-main-text text-[16px]">ABOUT US</p>
                            <ul className="w-fit flex flex-col space-y-4  mt-9">
                                <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">ABOUT US</li>
                                <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">OUR TEAM</li>
                                <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">OUR WORKS</li>
                                <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">FAQ</li>
                            </ul>
                        </div>
                        <div className="max-sm:mt-8 sm:w-2/3">
                            <p className="font-bold font-chakra text-main-text text-[16px]">POPULAR SERVICES</p>
                            <div className="flex max-sm:flex-col">
                                <div className="sm:w-1/2">
                                    <ul className="w-full flex flex-col space-y-4  mt-9">
                                        <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">TIRE REPAIR</li>
                                        <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">BRAKE REPAIR</li>
                                        <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">ENGINE REPAIR</li>
                                        <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">CHARGING REPAIR</li>
                                    </ul>
                                </div>
                                <div className="sm:w-1/2">
                                    <ul className="w-full flex flex-col space-y-4  mt-9">
                                        <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">COOLING SYSTEM</li>
                                        <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">WHEEL ALIGNMENT</li>
                                        <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">BATTERY STARTING</li>
                                        <li className="w-fit   relative pb-1 cursor-pointer pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[14px] font-bold font-chakra after:w-2 after:h-2 after:bg-main before:absolute before:w-0 before:transition-all before:duration-300 hover:before:w-full before:h-2 before:bottom-0 before:right-0 before:border-b-2 before:border-dashed before:border-main">SUPSPENSION REPAIR</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full max-xl:mt-12 xl:w-1/4">
                        <p className="font-bold font-chakra text-main-text text-[16px]">SUBSCRIBE</p>
                        <div className="mt-9">
                            <p className="font-chakra font-bold text-main-text uppercase ">your email</p>
                            <input type="email" name="email" placeholder="Enter Your Email Address" className="transition-all duration-300 outline-none w-full h-[39px] bg-transparent border-[#343434] focus:border-main  border-t-0 border-b-[1px] border-l-0 border-r-0 focus:outline-none text-[14px] text-[#c2c2c2] placeholder:text-[#343434] placeholder:font-bold" />
                        </div>
                        <div className="w-full mt-6">
                            <ServiceButton title={'SUBSCRIBE'} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}