import React, { useState } from "react";
import ServiceButton from "./serviceButton";

export default function HomeContacts() {

    const [t, setT] = useState(false);
    const handleClick = () => {
        setT(!t);
    };


    return (
        <>
            <div className="max-w-6xl mt-12 mx-auto max-xl:px-6">
                <div className="relative">
                    <h1 className='Absolute__Text_Third text-[40px]  sm:text-[30px] lg:text-[50px] xl:text-[66px] transition-all duration-300 hover:-top-12 cursor-pointer'>CONTACT US </h1>
                </div>
                <div className="text-main-text font-bold font-chakra text-[24px] sm:text-[42px] relative z-10">
                    REQUEST AN APPOINTMENT ONLINE
                </div>
                <div className="text-main-text font-chakra mt-6 max-sm:text-[14px]">After you submit the form, a representative will call you back with the <br /> information you’ll need to make an appointment.</div>
                <div className="mt-12">
                    <form className="flex flex-wrap w-full">
                        <div className="w-full sm:w-1/2 mt-4 sm:mt-12 sm:pr-4">
                            <p className="font-chakra font-bold text-main-text uppercase">your Name</p>
                            <input type="text" name="name" placeholder="Enter You Name" className="transition-all duration-300 outline-none w-full h-[39px] bg-transparent border-[#343434] focus:border-main  border-t-0 border-b-[1px] border-l-0 border-r-0 focus:outline-none text-[14px] text-[#c2c2c2] placeholder:text-[#343434] placeholder:font-bold" />
                        </div>
                        <div className="w-full sm:w-1/2 mt-4 sm:mt-12 sm:pl-4">
                            <p className="font-chakra font-bold text-main-text uppercase ">your email</p>
                            <input type="email" name="email" placeholder="Enter You Name" className="transition-all duration-300 outline-none w-full h-[39px] bg-transparent border-[#343434] focus:border-main  border-t-0 border-b-[1px] border-l-0 border-r-0 focus:outline-none text-[14px] text-[#c2c2c2] placeholder:text-[#343434] placeholder:font-bold" />
                        </div>
                        <div className="w-full sm:w-1/2 mt-4 sm:mt-12 sm:pr-4">
                            <p className="font-chakra font-bold text-main-text uppercase">SELECT DATE</p>
                            <input type={t ? 'date' : 'text'} onFocus={handleClick} name="date" placeholder="02/26/2023" className="transition-all duration-300 outline-none w-full h-[39px] bg-transparent border-[#343434] focus:border-main  border-t-0 border-b-[1px] border-l-0 border-r-0 focus:outline-none text-[14px] text-[#c2c2c2] placeholder:text-[#343434] placeholder:font-bold" />
                        </div>
                        <div className="w-full sm:w-1/2 sm:pl-4 mt-4 sm:mt-12">
                            <p className="font-chakra font-bold text-main-text uppercase">SELECT TIME</p>
                            <input type={t ? 'time' : 'text'} onFocus={handleClick} name="time" placeholder="Select Time" className="transition-all duration-300 outline-none w-full h-[39px] bg-transparent border-[#343434] focus:border-main  border-t-0 border-b-[1px] border-l-0 border-r-0 focus:outline-none text-[14px] text-[#c2c2c2] placeholder:text-[#343434] placeholder:font-bold" />
                        </div>
                        <div className="w-full mt-4 sm:mt-12">
                            <p className="font-chakra font-bold text-main-text uppercase">YOUR MESSAGE</p>
                            <input type='text' name="message" placeholder="Describe Your Problem" className="transition-all duration-300 outline-none w-full h-[39px] bg-transparent border-[#343434] focus:border-main  border-t-0 border-b-[1px] border-l-0 border-r-0 focus:outline-none text-[14px] text-[#c2c2c2] placeholder:text-[#343434] placeholder:font-bold" />
                        </div>
                        <div className="w-full mt-10 sm:mt-16">
                            <ServiceButton title={'SEND MESSAGE'} />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
