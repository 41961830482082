import { AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export default function SmallService(props) {
    return (
        <>
            <div className="GroupFilterHover  sm:w-[70%] md:w-1/3 relative after:absolute after:top-0 after:w-full after:h-[2px] after:bg-[#242424] after:left-0 group">
                <div className="py-6 relative after:absolute after:top-0 after:w-0 after:h-[2px] after:bg-main after:left-[50%] after:z-10 after:transition-all after:duration-300 group-hover:after:w-[50%] before:absolute before:top-0 before:w-0 before:h-[2px] before:bg-main before:right-[50%]  before:transition-all before:duration-300 before:z-20 group-hover:before:w-[50%] ">
                    <img src={props.icon} className="ImageFilterHover" />
                </div>
                <div className="text-[23px] font-bold font-chakra text-main-text transition-all duration-300 hover:text-main">{props.title}</div>
                <div className="text-[15px] text-main-text font-chakra mt-4">{props.desc}</div>
                <div className="mt-4">
                    <Link className='flex  BTN__HOVER space-x-4 text-main-text font-chakra font-bold'>
                        <div className="Service__Button__Small"> <AiOutlineRight /> </div>
                        <span className='link__span'>DETAILS SERVICE</span>
                    </Link>

                </div>
            </div>
        </>
    )
}