import React from "react";
import SmallYears from "./smallYears";


function HomeSecond() {
    return (
        <>
            <div className="w-full bg-[#121212] py-12">
                <div className="max-w-6xl mx-auto max-xl:px-6 flex max-md:flex-col">
                    <SmallYears year={'12+'} title={'More than 12 years of operation in the field of Car Services'} />
                    <SmallYears year={'22K+'} title={'International standard process and large factory system'} />
                    <SmallYears year={'500K+'} title={'Employees in the whole system include many leading engineers'} />
                    <SmallYears year={'99%'} title={'Service technology to satisfy customers needs and interests'} />
                </div>
            </div>
        </>
    )
}
export default HomeSecond