import React ,{useState , useEffect} from "react"
import Logo from '../../assets/img/logo.png';
import { Rotate as Hamburger } from 'hamburger-react'
import { Link } from "react-scroll";
export default function Header() {
    const [toggle, setToggle] = useState(false);
    const handleClick = () => {
      setToggle(!toggle);
    };

    const [stickyClass, setStickyClass] = useState('');

    useEffect(() => {
      window.addEventListener('scroll', stickNavbar);
      return () => window.removeEventListener('scroll', stickNavbar);
    }, []);
  
    const stickNavbar = () => {
      if (window !== undefined) {
        let windowHeight = window.scrollY;
        windowHeight > 50 ? setStickyClass('sticky-nav') : setStickyClass('');
      }
    };

    return (
        <>
            <div className={`w-full bg-[#161616] py-[30px] ${stickyClass}`}>
                <div className="max-w-6xl mx-auto max-xl:px-6 relative z-20">
                    <div className="flex justify-between items-center">
                        <div>
                            <img src={Logo} className="w-[186px] h-[35px]" />
                        </div>
                        <div className="">
                            <ul className="flex space-x-4 max-lg:hidden  xl:space-x-12">
                                <li>
                                    <Link to={''} className="Header__Link">Home</Link>
                                </li>
                                <li>
                                    <Link to={''} className="Header__Link">About</Link>
                                </li>
                                <li>
                                    <Link to={''} className="Header__Link">Services</Link>
                                </li>
                                <li>
                                    <Link to={''} className="Header__Link">Pricing</Link>
                                </li>
                                <li>
                                    <Link to={''} className="Header__Link">Blog</Link>
                                </li>
                                <li>
                                    <Link to={''} className="Header__Link">Contact</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <a href="to:+391234567789" className="Number font-chakra max-lg:hidden"> <span className="z-10 relative">+39 123 45 67 789</span> </a>
                        </div>
                        <div className="text-white border lg:hidden" onClick={handleClick}>
                            <Hamburger />
                        </div>
                    </div>
                    <div className= {toggle ? 'Open' : 'Close'}>
                        <div className="flex flex-col px-6 border-r-8 h-full space-y-8 border-main-text">
                            <div>
                                <img src={Logo} className="w-[186px] h-[35px]" />
                            </div>
                            <div className="">
                            <ul className="flex flex-col">
                                <li className="max-lg:border-b-2 border-main-text max-lg:py-2">
                                    <Link to={''} className="Header__Link ">Home</Link>
                                </li>
                                <li className="max-lg:border-b-2 border-main-text max-lg:py-2">
                                    <Link to={''} className="Header__Link ">About</Link>
                                </li>
                                <li className="max-lg:border-b-2 border-main-text max-lg:py-2">
                                    <Link to={''} className="Header__Link ">Services</Link>
                                </li>
                                <li className="max-lg:border-b-2 border-main-text max-lg:py-2">
                                    <Link to={''} className="Header__Link ">Pricing</Link>
                                </li>
                                <li className="max-lg:border-b-2 border-main-text max-lg:py-2">
                                    <Link to={''} className="Header__Link ">Blog</Link>
                                </li>
                                <li className="max-lg:border-b-2 border-main-text max-lg:py-2">
                                    <Link to={''} className="Header__Link ">Contact</Link>
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}