import React from "react"
import ServiceButton from "./serviceButton"
import AbsoluteText from "./absoluteText"
import MainImg from "../../assets/img/main-slider-bg.png";
import LeftImg from "../../assets/img/left-light-carousel.png";
import RightImg from "../../assets/img/right-light-carousel.png";
import ringer from "../../assets/zvuk-signala-mashiny_001.mp3";
import MidImg from "../../assets/img/aad.png";
function HomeFirst() {
    const audio = new Audio(ringer);
    audio.loop = true;

    return (
        <>
            <div className="w-full bg-bg-dark-light">
                <div className="max-w-6xl mx-auto flex max-lg:flex-col max-xl:px-6 relative z-0 pt-12 md:pt-28 pb-12 overflow-hidden">
                    <AbsoluteText title={'PROMOTORS'} />
                    <div className="w-full  mx-auto md:w-[40%]">
                        <h1 className="font-bold font-chakra text-[30px] sm:text-[40px] xl:text-[66px] text-main-text xl:leading-[70px]">GET COUPONS & OFFERS</h1>
                        <p className="text-[16px] sm:text-[18px] text-main-text font-mulish">Worry no more, now you can book your car services online and also get benefited from the coupons available. With online booking, you can save your time and money both.</p>
                        <div className="mt-4">
                            <ServiceButton title=' GET SERVICE ' />
                        </div>
                    </div>
                    <div className="w-full max-md:mt-12 md:w-[60%] relative group">
                        <img src={LeftImg} className="absolute scale-100 xl:scale-125 max-w-full top-0  z-20 hidden group-hover:block" />
                        {/* <img src={MidImg} className={'absolute  top-0 z-20 w-[150%] animate-spin '} /> */}
                        <img src={MainImg} className="relative  scale-100 xl:scale-125 max-w-full "  />
                        {/* <img src={MainImg} className="relative  scale-100 xl:scale-125 max-w-full " onMouseOver={() => {
                            audio.play();
                        }} onMouseOut={() => (audio.loop = false)} /> */}
                        <img src={RightImg} className="absolute scale-100 xl:scale-125 top-0 max-w-full hidden group-hover:block" />
                    </div>
                </div>
            </div>
        </>
    )
}



export default HomeFirst