import Bg from "../../assets/img/img-post-03-1170x507.jpg";
import Slider from "react-slick";
import SmallPopular from "./smallPopular";
import Blog1IMG from '../../assets/img/blog1.jpg';
import Blog2IMG from '../../assets/img/blog2.jpg';
import Blog3IMG from '../../assets/img/blog3.jpg';
import Blog4IMG from '../../assets/img/blog4.jpg';
import Blog5IMG from '../../assets/img/blog5.jpg';
import Blog6IMG from '../../assets/img/blog6.jpg';

export default function HomePopular() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:false,
        dots:false,
        responsive: [
            {
              breakpoint: 990,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 620,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
    };
    const posts = [
        {image:Blog1IMG , category:'Technology' , title:'HOW TO CHECK TRANSMISSION FLUID FAST' , date:'Jan , 09 2023'},
        {image:Blog2IMG , category:'Tuning' , title:'HOW TO MAKE THE ENGINE WORK EFFICIENTLY' , date:'Jan , 09 2023'},
        {image:Blog3IMG , category:'Store' , title:'HOW OFTEN SHOULD YOU WASH YOUR CAR?' , date:'Jan , 09 2023'},
        {image:Blog4IMG , category:'Sport Cars' , title:'CAR TUNE UP: WARNING SIGNS & COMPLETE CHECKLIST' , date:'Jan , 09 2023'},
        {image:Blog5IMG , category:'Store' , title:'THE WORLDS FIRST SUPERCAR THAT SHOWS THE TRUE POWER…' , date:'Jan , 09 2023'},
        {image:Blog6IMG , category:'Sport Cars' , title:'WHEEL ALIGNMENT VS. FRONT END ALIGNMENT' , date:'Jan , 09 2023'},
    ]
    return (
        <>
            <div className="w-full bg-bg-dark-light py-6 group">
                <div className="max-w-6xl mx-auto max-xl:px-6">
                    <div className="flex  flex-col sm:flex-row relative after:absolute after:bottom-0 after:w-0 after:h-[2px] after:bg-main after:left-[50%] after:z-10 after:transition-all after:duration-300 group-hover:after:w-[50%] before:absolute before:bottom-0 before:w-0 before:h-[2px] before:bg-main before:right-[50%]  before:transition-all before:duration-300 before:z-20 group-hover:before:w-[50%] ">
                        <div className="w-full text-main-text flex items-center pb-10 max-sm:py-6 font-bold font-chakra text-[24px] md:text-[42px] relative after:absolute after:w-full after:h-[2px] after:bottom-0 after:bg-[#242424]">
                            POPULAR ARTICLES
                        </div>
                    </div>
                </div>
                <div className="mt-12 max-w-6xl mx-auto relative z-0 after:absolute after:w-full after:h-full after:top-0 overflow-hidden after:left-0 after:bg-[rgba(0,0,0,.5)] group">
                    <img src={Bg} className="w-full h-full transition-all duration-300 group-hover:scale-125" />
                    <div className="absolute  z-20  bottom-0 left-0 px-2 pt-1 pb-4 sm:px-12 sm:pt-4 sm:pb-12">
                        <div className="w-fit text-main-text font-chakra font-bold relative after:absolute after:top-0 after:left-0 after:h-[2px] after:bg-main after:w-full pt-3 text-[15px] transition-all duration-300 hover:text-main cursor-pointer">REPAIR</div>
                        <div className="text-main-text font-bold font-chakra sm:text-[23px] transition-all duration-300 hover:text-main cursor-pointer mt-3">SIGNS YOUR CAR BATTERY  HAS <br /> TO BE REPLACED</div>
                    </div>
                </div>
                <div className="mt-12 max-w-6xl mx-auto max-xl:px-6">
                    <Slider {...settings}>
                     {
                        posts && posts.map((item,index) => {
                         return   <div className="p-2">
                                <SmallPopular image={item.image} category={item.category} title={item.title} date={item.date}  />
                            </div>
                        })
                     }
                    </Slider>
                </div>
            </div>
        </>
    )
}