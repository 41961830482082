import React from "react";
import { Link } from "react-router-dom";

function ServiceButton(props){
    return (
        <>
            <div className="Service__Button overflow-hidden relative">
                <Link to={'/'} className="relative z-20"><span>{props.title}</span></Link>
            </div>        
        </>
    )
}

export default ServiceButton
