import { Link } from "react-router-dom";
import {AiOutlineRight} from 'react-icons/ai';

export default function SmallPopular(props) {
  
    return (
        <>
            <div className="py-8 border-t-2 border-[#242424] relative after:absolute after:-top-1 after:w-0 after:h-[4px] after:bg-main after:left-[50%] after:z-10 after:transition-all after:duration-300 hover:after:w-[50%] before:absolute before:-top-1 before:w-0 before:h-[4px] before:bg-main before:right-[50%]  before:transition-all before:duration-300 before:z-20 hover:before:w-[50%] cursor-pointer">
              <div className="relative overflow-hidden  group-last">
                <img src={props.image} className="w-full transition-all duration-300 group-last:hover:scale-125" />
                    <div className="absolute  z-20  bottom-6 left-6 pt-3  w-fit text-main-text font-chakra font-bold  after:absolute after:top-0 after:left-0 after:h-[2px] after:bg-main after:w-full text-[15px] transition-all duration-300 hover:text-main cursor-pointer">
                        {props.category}
                    </div>
              </div>
              <div className="mt-3">
                <div className="font-bold font-chakra text-main-text text-[16px] sm:text-[20px] xl:text-[23px] transition-all duration-300 hover:text-main">{props.title}</div>
                <div className="font-chakra text-main-text text-[14px] mt-3"> {props.date} </div>
              </div>
              <div className="mt-5">
              <Link className='flex  BTN__HOVER space-x-4 text-main-text font-chakra font-bold'>
                        <div className="Service__Button__Small"> <AiOutlineRight /> </div>
                        <span className='link__span'>READ MORE</span>
                    </Link>
              </div>
            </div>
        </>
    )
}