import ServiceButton from "./serviceButton";
import SmallService from "./smallService";
import S1 from "../../assets/img/s1.png";
import S2 from "../../assets/img/s2.png";
import S3 from "../../assets/img/s3.png";
import S4 from "../../assets/img/s4.png";
import S5 from "../../assets/img/s5.png";
import S6 from "../../assets/img/s6.png";

export default function HomeService() {
    return (
        <>
            <div className="w-full bg-bg-dark-light py-24">
                <div className="max-w-6xl mx-auto max-xl:px-6">
                    <div className="flex justify-between flex-col sm:flex-row">
                        <div className="text-main-text flex items-center justify-center max-sm:py-6 font-bold font-chakra text-[24px] md:text-[42px]">OUR SERVICES</div>
                        <div className="flex max-sm:justify-center">
                            <ServiceButton title={'ALL SERVICES'} />
                        </div>
                    </div>
                    <div className="flex py-12 max-md:space-y-10 md:space-x-10 max-md:flex-wrap">
                        <SmallService icon={S1} title={'BRAKE REPAIR'} desc={'An overheated engine that leaves you stranded on the side of the road is a hassle. But overheating is one of many signs of trouble…'} />
                        <SmallService icon={S2} title={'ENGINE REPAIR'} desc={'Your vehicle just won’t start. You turn on the ignition, and all you get is the telltale clicking noise. Possibly followed by competing pronouncements...'} />
                        <SmallService icon={S3} title={'TIRE REPAIR'} desc={'Your steering and suspension systems work together to keep your tires on the pavement and your vehicle under control – until a power steering'} />
                    </div>
                    <div className="flex py-12 max-md:space-y-10 md:space-x-10 max-md:flex-wrap">
                        <SmallService icon={S4} title={'COOLING SYSTEM'} desc={'An overheated engine that leaves you stranded on the side of the road is a hassle. But overheating is one of many signs of trouble…'} />
                        <SmallService icon={S5} title={'BATTERY REPAIR'} desc={'Your vehicle just won’t start. You turn on the ignition, and all you get is the telltale clicking noise. Possibly followed by competing pronouncements...'} />
                        
                        <SmallService icon={S6} title={'STEERING REPAIR'} desc={'Your steering and suspension systems work together to keep your tires on the pavement and your vehicle under control – until a power steering'} />
                    </div>
                </div>
            </div>
        </>
    )
}
