import Bg from "../../assets/img/video-bg2.jpg"; 
export default function HomeVideo() {
    return (
        <>
            <div className="w-full bg-bg-dark-light py-6">
                <div className="max-w-6xl mx-auto max-xl:px-6">
                    <div className="flex  flex-col sm:flex-row relative after:absolute after:bottom-0 after:w-0 after:h-[2px] after:bg-main after:left-[50%] after:z-10 after:transition-all after:duration-300 hover:after:w-[50%] before:absolute before:bottom-0 before:w-0 before:h-[2px] before:bg-main before:right-[50%]  before:transition-all before:duration-300 before:z-20 hover:before:w-[50%] ">
                        <div className="w-full text-main-text flex items-center pb-10 max-sm:py-6 font-bold font-chakra text-[24px] md:text-[42px] relative after:absolute after:w-full after:h-[2px] after:bottom-0 after:bg-[#242424]">
                            MAINTENANCE & REPAIRS
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl mx-auto relative after:absolute after:w-full after:h-full after:top-0 overflow-hidden after:left-0 after:bg-[rgba(0,0,0,.5)] group">
                    <img src={Bg} className="transition-all duration-300 group-hover:scale-125" />
                </div>
            </div>
        </>
    )
}