export default function SmallThird(props){
   return(
    <>
             <div className="py-8 border-t-2 border-[#242424] relative after:absolute after:-top-1 after:w-0 after:h-[4px] after:bg-main after:left-[50%] after:z-10 after:transition-all after:duration-300 hover:after:w-[50%] before:absolute before:-top-1 before:w-0 before:h-[4px] before:bg-main before:right-[50%]  before:transition-all before:duration-300 before:z-20 hover:before:w-[50%] ">
                    <div className="flex space-x-6">
                        <div className="font-bold font-chakra text-main  text-[18px] sm:text-[23px]">{props.number}</div>
                        <div className="font-bold font-chakra text-main-text  text-[18px] sm:text-[23px]">{props.title}</div>
                    </div>
                    <div className="text-main-text mt-6 font-chakra text-[14px] sm:text-[16px]">
                        {props.description}
                    </div>    
            </div>
    </>
   ) 
}