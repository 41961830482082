import SmallThird from "./smallThird";

export default function HomeServiceThird() {
    return (
        <>
            <div className="bg-bg-dark-dark  sm:py-24">
                <div className="max-w-6xl mx-auto max-xl:px-6 flex lg:space-x-6 max-lg:flex-col max-lg:space-y-12">
                    <div className="lg:w-1/3 flex flex-col space-y-6">
                        <div className=" text-[24px] sm:text-[42px] sm:leading-[50px] text-main-text font-bold font-chakra tracking-[1px]">
                            HOW TO SERVICE YOUR CAR
                        </div>
                        <div className="text-main-text font-mulish text-[14px] sm:text-[16px] tracking-[1px]">
                            Rather than letting your services go by, take these steps to keep your car in good shape until you can afford a full service.
                        </div>

                    </div>
                    <div className="lg:w-2/3 flex flex-wrap">
                        <div className="md:p-4 md:w-1/2">
                            <SmallThird number="01" title={'MAKE AN APPOINTMENT'} description={'Promotors has made it easy to schedule an appointment online at a location near you in a few simple steps, easy schedule for customers.'} />
                        </div>
                        <div className="md:p-4 md:w-1/2">
                            <SmallThird number="02" title={'SELECT SERVICE'} description={'We specialize in car services and have more than 20 years of experience in cars. We are car guys from day one. We love and respect cars.'} />
                        </div>
                        <div className="md:p-4 md:w-1/2">
                            <SmallThird number="03" title={'CONFIRM REQUEST'} description={"Has your request been confirmed? Reduce no-shows, save time, and focus on serving clients is our top criterion."} />
                        </div>
                        <div className="md:p-4 md:w-1/2">
                            <SmallThird number="04" title={'GET YOUR CAR'} description={"It is a vehicle inspection that keeps your car in a reliable, safe and fully-functioning condition based on guidelines set out by the vehicle."} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}