import { AiOutlineCheck } from 'react-icons/ai';
import ServiceButton from './serviceButton';

export default function HomePricing() {
    return (
        <>
            <div className="full bg-bg-dark-light py-12">
                <div className="max-w-6xl mx-auto max-xl:px-6 flex lg:space-x-6 max-lg:flex-col max-lg:space-y-12">
                    <div className="sm:w-[60%] mx-auto  lg:w-1/3 flex flex-col space-y-6">
                        <div className="text-[24px] sm:text-[42px] sm:leading-[50px] text-main-text font-bold font-chakra tracking-[1px]">
                            AFFORDABLE PRICING PLANS
                        </div>
                        <div className="text-main-text font-mulish text-[16px] tracking-[1px]">
                            We provide the best car service, recommend the best products through an independent review process.
                        </div>
                        <div>
                            <ul className="flex-col space-y-2">
                                <li className="relative pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[16px] font-chakra after:w-2 after:h-2 after:bg-main">SAME DAY SERVICE</li>
                                <li className="relative pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[16px] font-chakra after:w-2 after:h-2 after:bg-main">CONVENIENT LOCATION</li>
                                <li className="relative pl-6 after:absolute after:left-0 after:top-2 text-main-text text-[16px] font-chakra after:w-2 after:h-2 after:bg-main">ONLINE APPOINTMENT</li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full sm:w-[60%] mx-auto  lg:w-1/3 bg-[#121212] p-4 sm:p-[50px] relative after:absolute after:top-0 after:w-0 after:h-[4px] after:bg-main after:left-[50%] after:z-10 after:transition-all after:duration-300 hover:after:w-[50%] before:absolute before:top-0 before:w-0 before:h-[4px] before:bg-main before:right-[50%]  before:transition-all before:duration-300 before:z-20 hover:before:w-[50%] ">
                        <div className="relative pb-6 after:bg-[#c2c2c2] after:absolute after:left-0 after:w-full after:h-[2px] after:bottom-0 w-fit mx-auto">
                            <div className="text-[15px] text-[#c2c2c2] font-chakra font-bold
                            text-center">PERSONAL</div>
                            <div className="text-main-text font-bold font-chakra text-[42px] text-center">$ <span>49.99</span></div>
                            <div className="text-center font-mulish text-main-text">per mounth</div>
                        </div>
                        <div>
                            <ul className='mt-8 flex flex-col space-y-3'>
                                <li className='relative flex text-main-text font-chakra text-[16px]'>
                                    Scheduled servicing <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                                <li className='relative flex text-main-text font-chakra text-[16px]'>
                                    Component wear reminders <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                                <li className='relative flex text-main-text font-chakra text-[16px]'>
                                    Introduce new revenue streams <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                                <li className='relative flex text-[#707070] font-chakra text-[16px]'>
                                    All routine maintenance <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                                <li className='relative flex text-[#707070] font-chakra text-[16px]'>
                                    24/7 customer service <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                            </ul>
                        </div>
                        <div className='flex justify-center mt-6'>
                            <ServiceButton title={'GET STARTED'} className={'w-full'} />
                        </div>
                    </div>

                    <div className="w-full sm:w-[60%] mx-auto  lg:w-1/3 bg-[#121212] p-4 sm:p-[50px] relative after:absolute after:top-0 after:w-0 after:h-[4px] after:bg-main after:left-[50%] after:z-10 after:transition-all after:duration-300 hover:after:w-[50%] before:absolute before:top-0 before:w-0 before:h-[4px] before:bg-main before:right-[50%]  before:transition-all before:duration-300 before:z-20 hover:before:w-[50%] ">
                        <div className="relative pb-6 after:bg-[#c2c2c2] after:absolute after:left-0 after:w-full after:h-[2px] after:bottom-0 w-fit mx-auto">
                            <div className="text-[15px] text-[#c2c2c2] font-chakra font-bold
                            text-center">PROFESSIONAL</div>
                            <div className="text-main-text font-bold font-chakra text-[42px] text-center">$ <span>99.99</span></div>
                            <div className="text-center font-mulish text-main-text">per mounth</div>
                        </div>
                        <div>
                            <ul className='mt-8 flex flex-col space-y-3'>
                                <li className='relative flex text-main-text font-chakra text-[16px]'>
                                    Scheduled servicing <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                                <li className='relative flex text-main-text font-chakra text-[16px]'>
                                    Component wear reminders <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                                <li className='relative flex text-main-text font-chakra text-[16px]'>
                                    Introduce new revenue streams <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                                <li className='relative flex text-main-text font-chakra text-[16px]'>
                                    All routine maintenance <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                                <li className='relative flex text-main-text font-chakra text-[16px]'>
                                    24/7 customer service <AiOutlineCheck className='absolute right-0 top-1' />
                                </li>
                            </ul>
                        </div>
                        <div className='flex justify-center mt-6'>
                            <ServiceButton title={'GET STARTED'} className={'w-full'} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}