import React from "react";
import HomeFirst from "./Components/homeFirst";
import HomeSecond from "./Components/homeYears";
import HomeService from "./Components/homeService";
import HomeVideo from "./Components/homeVideo";
import HomePricing from "./Components/homePrice";
import HomeServiceSecond from "./Components/homeServiceSecond";
import HomeServiceThird from "./Components/homeServiceThird";
import HomePopular from "./Components/homePopular";
import HomeContacts from "./Components/homeContact";

 function Home() {
    return(
        <>
            <HomeFirst />
            <HomeSecond />
            <HomeService />
            <HomeVideo />
            <HomePricing />
            <HomeServiceSecond />
            <HomeServiceThird />
            <HomePopular />
            <HomeContacts />
        </>
    )
}


export default Home