export default function SmallYears(props){
    return(
        <>
             <div className="max-sm:w-full sm:w-1/2 md:w-1/4 group">
                        <div className="text-[60px] text-main-text py-4 font-bold font-chakra
                            relative after:absolute after:bottom-0 after:w-full after:h-[2px]
                            after:bg-main-text after:left-0
                        ">
                            {props.year}
                        </div>
                        <div className="pr-10 text-main-text font-mulish py-4 relative after:absolute after:w-0 after:top-[-2px] after:h-[2px] after:right-[50%] after:bg-main before:absolute before:w-0 before:top-[-2px] before:h-[2px] before:left-[50%] before:bg-main after:transition-all after:duration-300 before:transition-all before:duration-300 group-hover:after:w-[50%] group-hover:before:w-[50%]">
                            {props.title}
                        </div>
                    </div>
        </>       
    )
}